importScripts('https://storage.googleapis.com/workbox-cdn/releases/5.1.2/workbox-sw.js');

if (workbox) {
    const {registerRoute} = workbox.routing;
    const {CacheFirst, StaleWhileRevalidate} = workbox.strategies;
    const {CacheableResponse} = workbox.cacheableResponse;

    console.log(`Workbox is loaded 🎉`);

    registerRoute(
        new RegExp('\.css$'),
        new StaleWhileRevalidate({
            cacheName: "css_assets",
            plugins: [
                new CacheableResponse({statuses: [0, 200]})
            ],
        })
    );

    registerRoute(
        new RegExp('\.js$'),
        new StaleWhileRevalidate({
            cacheName: "js_assets",
            plugins: [
                new CacheableResponse({statuses: [0, 200]})
            ],
        })
    );

    registerRoute(
        new RegExp('\.(png|gif|jpg|jpeg)$'),
        new CacheFirst({
            cacheName: "images_assets",
            plugins: [
                new CacheableResponse({statuses: [0, 200]})
            ],
        })
    );

    registerRoute(
        new RegExp('\.(eot|svg|ttf|woff|woff2)$'),
        new CacheFirst({
            cacheName: "font_assets",
            plugins: [
                new CacheableResponse({statuses: [0, 200]})
            ],
        })
    );
} else {
    console.log(`Workbox didn't load 😬`);
}